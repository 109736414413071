<template>
  <div>
    <b-container class="pos-fix">
      <b-row>
        <b-col cols="12">
          <div class="mb-4 mt-3 text-center f-24 text-bold">
            {{ Title || "ยืนยันเบอร์โทรศัพท์" }}
          </div>
          <div class="f-18 pt-5 mb-3">
            Please fill in the verification code sent to {{ $route.query.tel }}
          </div>
        </b-col>
        <b-col cols="12" class="text-center">
          <p class="color-grey">Otp Ref: {{ form.RefCode }}</p>
          <div class="my-3 d-flex justify-content-between">
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(1, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(2, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(3, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(4, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(5, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
            <input
              class="otp"
              type="number"
              inputmode="numeric"
              autocomplete="one-time-code"
              pattern="\d{6}"
              required
              @keyup="tabChange(6, $event)"
              @paste="handlePaste"
              @input="handleChange"
              oninput="if( this.value.length > 1 && this.value.length != 6)  this.value = this.value.slice(0,1)"
            />
          </div>
        </b-col>
        <b-col class="d-inline-flex align-items-center f-13">
          <div>Do not receive the code?</div>
          <b-button
            variant="link"
            class="ml-auto f-13"
            @click="getSendOTP()"
            :disabled="disable"
            ><u> {{ OTPMsg }}</u></b-button
          >
          <!-- <b-button variant="link" :disabled="disable" @click="getSendOTP()">
            <font-awesome-icon v-if="!disable" :icon="['fas', 'sync-alt']" />
            <span class="color-grey"> {{ OTPMsg }}</span></b-button
          > -->
        </b-col>
      </b-row>

      <div class="text-center py-3">
        <div @click="onClickPage()"><u>Change phone number</u></div>
      </div>

      <div class="register-bottom">
        <b-button
          block
          type="button"
          @click="handleSubmitViladateOTP()"
          :disabled="disableButton"
          >Next
        </b-button>
        <b-button
          class="mt-2"
          block
          type="button"
          variant="outline-primary"
          v-if="
            $store.state.editPhonenumber &&
            $store.state.theme.line_layout == 'Compact'
          "
          @click="
            $router.replace('/manage'),
              $store.commit('setEditPhoneNumber', false)
          "
          >ยกเลิก
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      form: {
        Telephone: this.$route.query.tel,
        RefCode: "",
        OTP: ""
      },
      Title: this.$store.state.theme.register_title,
      isLoading: true,
      OTPMsg: "Resend",
      second: 60,
      disable: false,
      disableButton: true
    };
  },
  computed: {},
  created: async function () {
    await this.getSendOTP();
  },
  methods: {
    digitValidate(ele) {
      ele.value = ele.value.replace(/[^0-9]/g, "");
    },

    tabChange(val, e) {
      let ele = document.querySelectorAll("input.otp");

      let otp = "";
      ele.forEach(el => (otp += el.value));

      if (otp.trim().length == 6) {
        this.disableButton = false;
      } else {
        this.disableButton = true;
      }
      if (e.keyCode == 37 && val != 1) return ele[val - 2].focus();
      else if (e.keyCode == 39 && val != 6) return ele[val].focus();
      else if (val == 1 && e.keyCode == 37) return;
      else if (val == 6 && e.keyCode == 39) return;
      if (val == 6 && ele[val - 1].value != "") {
        if (ele[val - 1].value.length > 1)
          return (ele[val - 1].value =
            ele[val - 1].value[ele[val - 1].value.length - 1]);
        return;
      }

      if (e.target.value.length > 1) {
        ele[val - 1].value = e.target.value[1];
        ele[val].focus();
        e.preventDefault();
        return;
      }
      if (ele[val - 1].value != "") {
        ele[val].focus();
      } else if (ele[val - 1].value == "") {
        ele[val - 2].focus();
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getSendOTP() {
      let timeout;
      this.disable = true;

      if (!timeout) {
        timeout = setInterval(() => {
          if (this.second < 60) {
            this.OTPMsg = `Resend (${this.second}) `;
          }
          if (this.second > 0) {
            this.second--;
          } else {
            this.second = 60;
            this.disable = false;

            this.OTPMsg = "Resend";
            clearInterval(timeout);
          }
        }, 1000);
        let reqOTP = {
          Telephone: this.form.Telephone
        };
        this.$axios
          .post(`${process.env.VUE_APP_API}/api/v1/user/SendOTP`, reqOTP)
          .then(async data => {
            this.isLoading = false;
            if (data.result == 1) {
              this.form.RefCode = data.detail.ref_code;
              document.querySelectorAll("input.otp")[0].focus();
            } else {
              this.isLoading = false;
            }
          });
      }
    },
    handleSubmitViladateOTP() {
      let ele = document.querySelectorAll("input.otp");
      let otp = "";
      ele.forEach(el => (otp += el.value));
      this.form.OTP = otp;
      this.isLoading = true;
      this.$emit("submitValidateOTP", this.form);
      this.isLoading = false;
    },

    onClickPage() {
      this.$emit("clickChangePhone", this.type);
    },
    handleChange(e) {
      const val = e.target.value;

      if (!val) {
        e.preventDefault();
        return;
      } else if (val.trim().length > 1) {
        e.clipboardData = {
          getData: () => val.trim()
        };
        // handleOnPaste(e);
        this.handlePaste(e);
      }
    },

    handlePaste(e) {
      e.preventDefault();

      let value = e.clipboardData.getData("text");

      let ele = document.querySelectorAll("input.otp");
      ele.forEach((el, index) => (el.value = value[index]));
      return this.handleSubmitViladateOTP();
    }
  }
};
</script>

<style lang="scss" scoped>
input.otp {
  width: 52px;
  height: 52px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #707070;
  // border-radius: 0.25rem;
  border-radius: 0px !important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;

  -khtml-border-radius: 0;
}

.btn-otp {
  border-bottom: 1px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  border-radius: 0px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
::v-deep.btn-link {
  color: black !important;
}
</style>
